@font-face {
  font-family: 'Bodoni Classic Bold';
  src: url('./assets/fonts/Bodoni Classico Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Medium';
  src: url('./assets/fonts/futuramedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Light';
  src: url('./assets/fonts/FuturaLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* Ken Burns effect styles */
.bg-cover {
  background-size: cover;
  background-position: center;
}

.animate-kenburns {
  animation: kenburns 20s ease-in-out infinite;
  transform-origin: center;
}

/* Ensure smooth transitions between slides */
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* Ensure images cover the full viewport */
.h-screen {
  height: 100vh;
}

.overflow-hidden {
  overflow: hidden;
}
