@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Bodoni Classic Bold', serif;
  }
  body {
    font-family: 'Futura Medium', sans-serif;
  }
}


@layer utilities {
  .font-primary {
    font-family: 'Futura Light';

  }

  .font-secondary {
    font-family: 'Bodoni Classic Bold';
  }

  .font-bold {
    font-family: 'Futura Medium';
  }

  .font-semibold {
    font-family: 'Futura Medium';
  }



  .button-primary {
    @apply  btn text-white bg-transparent uppercase border-2 rounded-none border-white font-secondary bg-primary hover:text-white/50 hover:border-white/50 hover:scale-110 duration-300;
  }
  .button-secondary {
    @apply  btn text-primary bg-transparent uppercase border-2 rounded-none border-primary font-secondary bg-primary hover:text-primary/50 hover:border-primary/50 hover:scale-110 duration-300;
  }
  
 
 .btn {
 @apply font-semibold px-6 bg-transparent hover:bg-transparent
 }

}